<template>
    <div class="Result_">
        <header>
            <div class="label_box">
                <span class="label_text label_text_left">婚姻运势</span>
                <span class="label_text label_text_right" @click="nameMatchingSkip">
                    <i class="hot_img_box">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG637.pic" alt="">
                    </i>
                    <i class="fire_img_box">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG636.pic" alt="">
                    </i>
                    姓名配对
                </span>
            </div>
        </header>
        <section>
            <!-- <div class="nav_box_">
                <nav>
                    <router-link active-class="activateStyle_" :to="'/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/information'" class="nav_text"> 基本信息 <i  class="underline_"></i></router-link >
                    <router-link active-class="activateStyle_" :to="'/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/analysis'" class="nav_text" >婚姻分析<i  class="underline_"></i></router-link>
                    <router-link active-class="activateStyle_" :to="'/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/suggest'" class="nav_text">老师建议<i  class="underline_"></i></router-link >
                    <router-link active-class="activateStyle_" :to="'/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/increase'" class="nav_text">增运方式<i  class="underline_"></i></router-link >
                </nav>
                <div class="content_box_">
                    <keep-alive>
                        <router-view></router-view>
                    </keep-alive>

                </div>
                <div class="nav_img_box_" @click="calculationOfEmotionalMarriageBannerSkip">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG622.png" alt="">
                </div>
                <div class="paging_box_">
                    <span class="paging_left_bg_ span_bg_box_" @click="paginationcallback('relief')"><span class="span_symbol_">&lt;</span>上一页</span>
                    <span class="paging_text_">{{pagination_}}/4</span>
                    <span class="paging_right_bg_ span_bg_box_" @click="paginationcallback('gagarin')">下一页<span class="span_symbol_">&gt;</span></span>
                </div>
            </div> -->
        <div class="info_color_box_"><i class="icon_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG1300.pic" alt=""></i> 恭喜你！成功领取你的婚姻运势报告。</div>
        <div class="info_text_box_">请仔细查收哈，测试报告公众号领取后可反复查阅哦~</div>
        <div class="content_box_">
            <h1 class="title_">您的信息</h1>
            <div class="content_name_text_box_">
                <p class="name_ span_style__1"><span class="text__">姓</span>名：{{info_data.name}}</p>
                <p class="xex_ span_style__1"><span class="text__">性</span>别：{{info_data.sex}}</p>
                <p class="date_of_birth_"><span class="text__">出生日期</span>：</p>
                <p class="Gregorian_calendar_ span_style__1"><span class="text__">公</span>历：{{`${lunar_calendar_value.cYear}年${lunar_calendar_value.cMonth}月${lunar_calendar_value.cDay}日${lunar_calendar_value.tiem_}时`}}</p>
                <p class="lunar_calendar_ span_style__1"><span class="text__">阴</span>历：{{`(${lunar_calendar_value.yearCn_})${lunar_calendar_value.yearCn}${lunar_calendar_value.monthCn}${lunar_calendar_value.dayCn}${lunar_calendar_value.tiemCn_}`}}</p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">八字排盘</h1>
            <div class="horoscope_box">
                    <div class="eight_layout">
                        
                        <Paipan
                            :sex="orderinfo.sex"
                            :data="orderinfo.eightChara"
                            style="width:calc(100% - 30px);padding-left:15px"
                        />
                         <h2 class="text">五行分析喜用字</h2>
                        <div class="tit1 ml-15">
                            五行个数
                        </div>
                        <div class="txt ml-15">
                            金{{ orderinfo.askTao.ori_qk_score["金"] }} 木{{
                            orderinfo.askTao.ori_qk_score["木"]
                            }}
                            水{{ orderinfo.askTao.ori_qk_score["水"] }} 火{{
                            orderinfo.askTao.ori_qk_score["火"]
                            }}
                            土{{ orderinfo.askTao.ori_qk_score["土"] }}
                        </div>
                        <div class="txt ml-15">
                            个人运势不是按照五行缺什么就补什么，<span class="color-red"
                            >应以先天八字中五行同类和异类互为平衡原则，补充最需要的五行作为喜用。</span
                            >
                        </div>
                        <div class="txt">
                            您的日主天干属<span class="color-red">{{
                            orderinfo.askTao.benming
                            }}</span
                            >，经计算，您的八字同类五行是
                            <span
                            v-for="(value, key, index) in orderinfo.askTao.ww_score_ratio"
                            :key="key"
                            class="color-red"
                            >{{ key }}<span v-if="index == 0">、</span></span
                            >
                            。
                        </div>
                        <div class="center">
                            <img
                            style="width:70%"
                            src="//cdn.names.org.cn/website/Content/szname/img/WxGif2.gif"
                            />
                        </div>
                        <WuxingCate
                            :data="orderinfo.askTao"
                            :qk_score_ratio="orderinfo.askTao.qk_score_ratio"
                            style="padding:0 15px;margin:15px 0"
                        />
                        <div class="tit1 ml-15">
                            五行含量
                        </div>
                        <div class="center wchl">
                            <!-- 五行含量组件 -->
                            <WuxingHl
                            :score="orderinfo.askTao.qk_score"
                            :qk_score_ratio="orderinfo.askTao.qk_score_ratio"
                            />
                        </div>
                        <!-- 五行喜用分析开始 -->
                        <div class="tit1 ml-15">
                            五行喜用分析
                        </div>
                        <div class="txt ml-15">
                            同类含量：{{ WW[0] }} + {{ WW[1] }}＝{{ WWrd }}
                        </div>
                        <div class="txt ml-15">
                            异类含量：{{ DW[0] }} ＋ {{ DW[1] }} ＋{{ DW[2] }}＝{{ DWrd }}
                        </div>
                        <div class="txt ml-15">综合旺弱判定</div>
                        <div class="txt ml-15">
                            同类含量/异类含量＝{{ (WWrd / DWrd).toFixed(2) }}
                        </div>
                        <div class="txt ml-15">经准确分析，该八字的喜用如下：</div>
                        <div class="tit1 ml-15">喜用五行</div>
                        <div class="txt ml-15 xjwx">
                            喜用五行:
                            <em
                            :class="enums[key]"
                            v-for="(value, key) in orderinfo.askTao.asktao_result['喜用']"
                            :key="key"
                            >{{ key }}</em
                            >
                        </div>
                        <div class="txt ml-15 xjwx">
                            忌用五行：
                            <em
                            :class="enums[key]"
                            v-for="(value, key) in orderinfo.askTao.asktao_result['忌用']"
                            :key="key"
                            >{{ key }}</em
                            >
                        </div>
                        <!-- 五行结束 -->
                        <div class="txt ml-15">
                            根据上述分析过程，已详细为您推算出您的五行喜用，后天通过补足、增旺五行，成就一生幸福。
                        </div>
                        <div class="txt ml-15">
                            综合该八字五行喜用分析和五行平衡，找另一半或者找朋友时，选择五行
                            <span
                            v-for="(value, key, index) in orderinfo.askTao.asktao_result['喜用']"
                            :key="index"
                            class="color-red"
                            >【{{ key }}】</span
                            >
                            旺的，有利于感情稳定，幸福长久。
                        </div>
                    </div>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的婚前性格</h1>
            <div class="content_text_box_">
                <p v-html="test_info.marriage_before_xg.one"></p>
                <p v-html="test_info.marriage_before_xg.two"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的先天桃花运</h1>
            <div class="content_text_box_">
                <p style="font-weight:bold;padding-bottom:0;">正桃花分析：</p>
                <p v-html="test_info.py_status.zheng"></p>
                <p style="font-weight:bold;padding-bottom:0;">烂桃花分析：</p>
                <p v-html="test_info.py_status.fan"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的先天感情婚姻是怎样的</h1>
            <div class="content_text_box_">
                <p v-html="test_info.m_cate.desc"></p>
                <p style="color:#67386F;">
                    温馨提示：您的先天婚姻是根据您的出生时辰，结合易经原理，仔细分析的结果，描述的是您先天命理中婚姻感情的状况。可供您在做婚恋选择时参考，找到真正爱您的另一半。
                </p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的本命姻缘</h1>
            <div class="content_text_box_">
                <p v-html="test_info.bm_marriage"></p>
            </div>
        </div>
         <div class="content_box_">
            <h1 class="title_">老师的婚配建议</h1>
            <div class="content_text_box_">
                <p  v-html="test_info.marriage_advice.one"></p>
                <p v-html="test_info.marriage_advice.two"></p>
                <p v-html="test_info.marriage_advice.three"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的真命分析</h1>
            <div class="content_text_box_">
               <p v-html="test_info.right_analysis"> </p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">桃花好的年份</h1>
            <div class="content_text_box_">
               <p v-html="test_info.py_status_after.zheng"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">容易遇到烂桃花的年份</h1>
            <div class="content_text_box_">
                <p  v-html="test_info.py_status_after.fan"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">感情容易生变的年份</h1>
            <div class="content_text_box_">
                <p  v-html="test_info.py_over"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">八字姻缘改运建议</h1>
            <div class="content_text_box_">
                <p style="font-weight:bold;padding-bottom:0;">脱单技巧</p>
                <p  v-html="test_info.yy_method.doggo"></p>
                <p style="font-weight:bold;padding-bottom:0;">催旺爱情建议</p>
                <p  v-html="test_info.yy_method.ml"></p>
                <p style="color:#67386F;">（注：以上红袋子包裹干货若破碎霉变需及时更换，有效期为一年，次年除夕前更换为宜）</p>
            </div>
        </div>
        <div class="section_bottom_text_box_">
           您已看完报告所有内容，如果还有其他疑问
        </div>
        <div class="section_bottom_text_box_">
           可添加老师微信继续咨询哦~
        </div>

        <div class="teacher_consult_img_box_ animation_scaling" @click="consultingTeacherSkip('1')">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG1312.png" alt="">
        </div>

            <!-- 热门推荐 -->
            <div class="hot_recommend_box_" id="hotRecommend">
                <h1 class="h1_title_">热门推荐</h1>
                <div class="img_card_box_" @click="cardSkip('1')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG673.png" alt="">
                    <!-- <h2 class="card_text_"> 男女姓名配对分析  <span class="span_color_">231654人已经购买</span></h2> -->
                </div>
                <div class="img_card_box_" @click="cardSkip('2')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG1459.png" alt="">
                    <!-- <h2 class="card_text_"> 姻缘合婚分析  <span class="span_color_">326584人已经购买</span></h2> -->
                </div>
                <div class="img_card_box_" @click="cardSkip('3')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG677.png" alt="">
                    <!-- <h2 class="card_text_"> 姓名测试打分  <span class="span_color_">236564人已经购买</span></h2> -->
                </div>
                <div class="img_card_box_ advisory_teacher_" @click="consultingTeacherSkip('2')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG631.png" alt="">
                </div>
            </div>
        
        </section>
        <footer v-if="true">
            <div class="bottom_buttom_box">
                <span class="left_box span_" @click="anchorPoint">更多测试</span>
                <span class="right_box span_" @click="relationshipTitTesting">
                    <i class="icon_img_box_">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG628.pic" alt="">
                    </i>
                    恋爱契合度测试
                </span>
            </div>
        </footer> 
        <div class="fixation_box_">
            <div class="img_box_" @click="rightFixationSkip('1')"><img src="https://cdn.zhiyileiju.cn/WechatIMG629.png" alt=""></div>
            <div class="img_box_" @click="rightFixationSkip('2')"><img src="https://cdn.zhiyileiju.cn/WechatIMG1455.png" alt=""></div>
            <div class="img_box_" @click="rightFixationSkip('3')"><img src="https://cdn.zhiyileiju.cn/WechatIMG634.png" alt=""></div>
        </div>
        <div class="complaint_box_" @click="complaintButtom">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG562.png" alt="">
        </div>
        <div class="manual_service_shade" @click="manualServicePopupClose" v-if="manual_service_shade_fal"></div>
        <div class="manual_service" v-if="manual_service_shade_fal"><img src="https://cdn.zhiyileiju.cn/WechatIMG1465.jpeg" alt=""></div>
    </div>
</template>
<script>
import Paipan from "../../components/Paipan.vue";
import WuxingCate from "../../components/WuxingCate.vue";
import WuxingHl from "../../components/WxHanLg.vue";
import solarLunar from "../../components/datepick/solarlunar";
import { $orderNameDetail,$order } from "../../api/home_marriage_test"
import { $stat } from "../../api/stat"
export default {
    components: {
        Paipan,WuxingCate,WuxingHl
    },
    data () { 
        return{
            fixedbtn:false,
            pagination_:1,//当前页数
            manual_service_shade_fal:false,
             result_data:{},//结果页面数据
            info_data:{},//用户信息
            lunar_calendar_value:{
                cYear:"",
                yearCn_:"",
                cMonth:"",
                yearCn:"",
                cDay:"",
                monthCn:"",
                tiem_:"",
                dayCn:"",
                tiemCn_:"",
            },//农历时间
            test_info:{
                marriage_before_xg:{
                    one:'',
                    two:''
                },
                py_status:{
                    zheng:'',
                    fan:''
                },
                bm_marriage:'',
                marriage_advice:{
                    one:'',
                    two:'',
                    three:''
                },
                right_analysis:'',
                py_status_after:{
                    zheng:'',
                    fan:''
                },
                py_over:'',
                yy_method:{
                    doggo:'',
                    ml:''
                },
                m_cate:{
                    desc:''
                },


                m_cate:{},
                m_xg:{},
                m_zwh:{},

            },//测试信息
            orderinfo: {
                bDate: [2021],
                nongli: {},
                sex:'',
                eightChara: {
                bazi: [
                    {
                    y: "辛丑",
                    },
                    {
                    m: "乙未",
                    },
                    {
                    d: "甲子",
                    },
                    {
                    h: "甲子",
                    },
                ],
                nayin: [
                    {
                    y: "壁上土",
                    },
                    {
                    m: "沙中金",
                    },
                    {
                    d: "海中金",
                    },
                    {
                    h: "海中金",
                    },
                ],
                zao: [
                    {
                    y: "辛丑",
                    },
                    {
                    m: "",
                    },
                    {
                    d: "",
                    },
                    {
                    h: "",
                    },
                ],
                },
                askTao: {
                ori_qk_score: {
                    金: 5,
                    木: 8,
                    水: 3,
                    火: 3,
                    土: 9,
                },
                asktao_result: {
                    喜用: {
                    水: 1.5,
                    金: 2.5,
                    },
                    忌用: {
                    木: 5.6,
                    火: 4.5,
                    土: 18,
                    },
                },
                qk_score_ratio: {
                    金: "6%",
                    木: "11%",
                    水: "25%",
                    火: "12%",
                    土: "46%",
                },
                ww_score_ratio: {
                    水: "38%",
                    金: "62%",
                },
                dw_score_ratio: {
                    木: "20%",
                    火: "16%",
                    土: "64%",
                },
                qk_score: {
                    金: 2.5,
                    木: 5.6,
                    水: 1.5,
                    火: 4.5,
                    土: 18,
                },
                },
            },
            enums: {
                木: "xys-mu",
                土: "xys-tu",
                火: "xys-huo",
                水: "xys-shui",
                金: "xys-jin",
            },
        }
    },
    watch: {
    '$route': function() {
        this.pagination_ = this.$route.meta.pagination
    }
    },
    created() {
        $stat(`v1_home_marriage_test_result`);
        window.addEventListener("scroll", this.handleScroll);
        this.pagination_ = this.$route.meta.pagination;
        this.orderNameDetailDataRequest();

        $order( this.$route.params.id ).then ( (res ) => {
            this.orderinfo =  res.data.data
            // console.log('order',this.orderinfo)
        })
    },
    computed: {
		// is_weixn() {
		// 	var ua = navigator.userAgent.toLowerCase();
		// 	if (ua.match(/MicroMessenger/i) == "micromessenger") {
		// 		return true;
		// 	} else {
		// 		return false;
		// 	}
		// },
        WW() {
        return Object.keys(this.orderinfo.askTao.ww_score_ratio);
        },
        DW() {
        return Object.keys(this.orderinfo.askTao.dw_score_ratio);
        },
        WWrd() {
        return Object.keys(this.orderinfo.askTao.ww_score_ratio)
            .reduce(
            (a, b) =>
                this.orderinfo.askTao.qk_score[a] +
                this.orderinfo.askTao.qk_score[b]
            )
            .toFixed(1);
        },
        DWrd() {
        let a = 0;
        Object.keys(this.orderinfo.askTao.dw_score_ratio).forEach((key) => {
            a = a + this.orderinfo.askTao.qk_score[key];
        });
        return a.toFixed(1);
        },
    },
    methods:{
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        orderNameDetailDataRequest() {//用户信息接口
            let dataRequest_data_ = {
                order_id: this.$route.params.id,
                name_id: 0,
                mnew:1
            }
            $orderNameDetail(dataRequest_data_).then( ( res ) => {//详情数据
                if ( res.status === 200) {
                    this.result_data = res.data.data;
                    // this.orderinfo = res.data.data
                    this.info_data = res.data.data.base
                    this.test_info = this.result_data.marriageDataNew
                    let date_arr = res.data.data.base.birth_date.substr(0,10).split("-")
                    let time_arr = res.data.data.base.birth_date.substr(11,8).split(":")
                    date_arr.push(...time_arr)
                    this.lunar_calendar_value = solarLunar.solar2lunar(//获取农历
                        date_arr[0],
                        date_arr[1],
                        date_arr[2],
                    );
                    this.lunar_calendar_value.tiem_ = time_arr[0]//时
                    this.lunar_calendar_value.tiemCn_ = (res.data.data.base.moon_time.split("#"))[1]//农历时
                    this.lunar_calendar_value.yearCn_ = (res.data.data.base.moon_time.split("#"))[0]//农历时
                }
                
            })
        },


        nameMatchingSkip() { //姓名配对按钮
            $stat("v1_marry_result_top_buttom")
            window.location.href="https://jx2.gyqingqing.com/wechatmatch?fromk=dbpd"
        },
        calculationOfEmotionalMarriageBannerSkip() {//感情合婚测算 banner
            window.location.href="https://jx2.gyqingqing.com/wechatmatch?fromk=zbpd"
        },
        paginationcallback(params){//翻页
            if( params === 'relief' ) { //上一页
                this.pagination_--
            }else if( params === 'gagarin' ) { //下一页
                 this.pagination_++
            }
            
            if ( this.pagination_ < 1 ) {
                this.pagination_ = 4
            }else if ( this.pagination_ > 4) {
                this.pagination_ = 1
            }
            switch (this.pagination_) {
                case 1:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/information')
                    break;
                case 2:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/analysis')
                    break;
                case 3:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/suggest')
                    break;
                case 4:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/increase')
                    break;
            
                default:
                    break;
            }
        },
        //热门推荐
        // https://jx2.gyqingqing.com/wechatmatch?fromk=dbpd
        cardSkip(type) {//男女姓名配对分析
            $stat(`v1_marry_result_card_${type}`)
            switch (type) {
                case '1':
                    window.location.href="https://jx2.gyqingqing.com/wechatmatch?fromk=rmpd1"
                    break;
                case '2':
                    window.location.href="https://jx2.gyqingqing.com/wechatceyun?fromk=rmpd2"
                    break;
                case '3':
                    window.location.href="https://jx2.gyqingqing.com/wechattestname?fromk=rmdf"
                    break;
            
                default:
                    break;
            }
        },
        consultingTeacherSkip(id) {// 咨询老师按钮
            $stat(`v1_marry_result_center_consult_buttom_${id}`)
            this.manual_service_shade_fal = true
            // this.$router.push('/mini_program_page/result?qr=1')
        },
        anchorPoint () {//锚点
            $stat(`v1_marry_result_top_left_buttom`)
            document.querySelector("#hotRecommend").scrollIntoView({behavior: "smooth", block: "start" });
        },
        relationshipTitTesting () {//恋爱契合度测试
            $stat(`v1_marry_result_top_right_buttom`)
            window.location.href="https://jx2.gyqingqing.com/wechatmatch?fromk=dgpd"
        },
        rightFixationSkip(type) {//右边屏幕固定按钮
            $stat(`v1_marry_result_right_fixation_buttom_${type}`)
            switch (type) {
                case '1':
                    window.location.href="https://jx2.gyqingqing.com/wechatmatch?fromk=xfpd"
                    break;
                case '2':
                    window.location.href="https://jx2.gyqingqing.com/wechatceyun?fromk=xfpd2"
                    break;
                case '3':
                    window.location.href="https://jx2.gyqingqing.com/wechattestname?fromk=xfdf"
                    break;
            
                default:
                    break;
            }
        },
        complaintButtom () {//投诉
            $stat("v1_marry_result_complaint");
            window.location.href = 'https://www.wjx.top/vj/hd0JNnc.aspx'
        },
        manualServicePopupClose () {//关闭弹窗
            this.manual_service_shade_fal = false
        },
    }
}
</script>
 <style lang="scss" scoped>
    .Result_{
        header{
            position: fixed;
            top: 0;
            width: 7.5rem;
            z-index: 2;
            .label_box{
                width: 100%;
                height:.8rem;
                .label_text{
                    display: inline-block;
                    width: 50%;
                    height:.8rem;
                    line-height: .8rem;
                    font-size: .32rem;
                    font-weight: bold;
                    text-align: center;
                }
                .label_text_left{
                    float: left;
                    color: #FFFFFF;
                    background: #C91C18;
                }
                .label_text_right{
                    position: relative;
                    float: right;
                    box-sizing: border-box;
                    border: 1px solid #C91C18;
                    color: #C91C18;
                    background: #FFFFFF;
                    .hot_img_box{
                        position: absolute;
                        width: .46rem;
                        right: .74rem;
                        top: -.14rem;
                    }
                    .fire_img_box{
                        position: absolute;
                        width: .24rem;
                        left: .8rem;
                        top: 0;

                    }
                }
            }
        }
        section{
            padding-top: 1.2rem;
            padding-bottom: 1.11rem;
            .nav_box_{//导航栏及内容
                nav{
                    position: sticky;
                    top: .8rem;
                    padding-top: .37rem;
                    padding-bottom: .76rem;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    z-index: 1;
                    background: #fff;
                    .nav_text{
                        position: relative;
                        color: #DD5845;
                        font-size: .32rem;
                    }
                    .activateStyle_{
                         .underline_{
                            position: absolute;
                            bottom: -.13rem;
                            left: 9%;
                            width: .98rem;
                            height: .05rem;
                            background: #DD5845;
                        }
                    }
                }
                .nav_img_box_{
                    width: 7.50rem;
                    margin-bottom: 0.3rem;
                }
                .paging_box_{
                    position: relative;
                    overflow: hidden;
                    .paging_text_{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        color: #C91C18;
                        font-size: .36rem;
                        // font-weight: bold;
                    }
                    .span_bg_box_{
                        width: 2.32rem;
                        height: .63rem;
                        line-height: .63rem;
                        color: #fff;
                        font-size: .32rem;
                        text-align: center;
                        background: url('https://cdn.zhiyileiju.cn/WechatIMG623.pic') no-repeat;
                        background-size: 100% 100%;
                        .span_symbol_{
                            font-size: .34rem;
                        }
                    }
                    .paging_left_bg_{
                        float: left;
                        margin-left: .7rem;
                        .span_symbol_{
                            margin-right: .08rem;
                        }
                    }
                    .paging_right_bg_{
                        float: right;
                        margin-right: .7rem;
                        .span_symbol_{
                            margin-left: .08rem;
                        }
                    }
                }
            }
            .info_color_box_{
                position: relative;
                width: 5.74rem;
                margin-left: 1.16rem;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E7010E;
                .icon_img_{
                    position: absolute;
                    width: .32rem;
                    top: 0.05rem;
                    left: -0.5rem;
                }

            }
            .info_text_box_{
                position: relative;
                width: 5.74rem;
                margin-left: 1.16rem;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #323232;
                margin-bottom: .5rem;
                line-height: .6rem;
            }

            
            .content_box_{
            width: 7.18rem;
            border: .03rem solid #DC5744;
            opacity: 0.7;
            border-radius: .30rem;
            padding-bottom: .6rem;
            margin: 0 auto;
            margin-bottom: .48rem;
            .title_{
                width: 100%;
                height: .78rem;
                line-height: .78rem;
                font-size: .36rem;
                color: #C91C18;
                text-align: center;
                font-weight: bold;
                background: #fae7e5;
                margin-top: .25rem;
            }
            .content_name_text_box_{ //您的信息
                // width: 100%;
                padding-left: .69rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .27rem;
                }
                .name_{
                    margin-top: .35rem;
                }
                .span_style__1{
                    .text__{
                        letter-spacing:2em
                    }
                }
            }
            .content_text_box_{
                padding: 0 .35rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .35rem;
                }
                //八字盘
            }
            .horoscope_box{
                width: 7.08rem;
                margin: 0 auto;
                border-radius: .3rem;
                text-align: center;
                background: #fff;
                // margin-bottom: 1rem;
                padding-top: .03rem;
                // padding-bottom: .5rem;
                margin-top: .36rem;
                .eight_layout{
                    text-align: left;
                    .text{
                        text-align: center;
                        font-size: .35rem;
                        margin: .3rem 0;
                    }
                    .center{
                        text-align: center;
                    }
                    .tit1 {
                        font-size: .3rem;
                        font-weight: bold;
                        color: #020202;
                        line-height: 1.8em;
                    }
                    .ml-15 {
                        margin-left: .25rem;
                    }

                    .txt {
                        font-size: .3rem;
                        font-weight: 400;
                        color: #020202;
                        line-height: 2em;
                        margin: .06rem .25rem;
                        margin-left: .25rem;
                    }
                    .wchl{
                        margin-left: .4rem;
                    }
                    .xjwx{
                        em {
                            border-radius: 50%;
                            width: .60rem;
                            height: .60rem;
                            color: white;
                            font-size: .4rem;
                            line-height: .6rem;
                            display: inline-block;
                            text-align: center;
                            margin-right: 3px;
                            font-style: normal;
                        }
                    } 
                }
            }
            
            
            }
            .section_bottom_text_box_{
                text-align: center;
                font-size: .32rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #525252;
                margin-top: .28rem;
                // line-height: 41px;
            }
            .teacher_consult_img_box_{
                width: 6.5rem;
                margin: auto;
                margin-top: .28rem;
                margin-bottom: .3rem;
            }

            .hot_recommend_box_{ //热门推荐
                // margin-top:1.3rem;
                .h1_title_{
                    width: 7.5rem;
                    height: .78rem;
                    font-size: .36rem;
                    font-weight: bold;
                    line-height: .78rem;
                    text-align: center;
                    color: #C91C18;
                    background: rgba(220,87,68,.2);
                    margin-bottom: .48rem;
                }
                .img_card_box_{
                    width: 7.18rem;
                    margin: 0 auto;
                    margin-bottom: .2rem;
                    .card_text_{
                        font-size: .24rem;
                        font-weight: normal;
                        color: #C91C18;
                        .span_color_{
                            color: #525252;
                        }
                    }
                }
                .advisory_teacher_{
                    margin-top: .4rem;
                    
                }
            }
        }
        footer{
            overflow: hidden;
            // position: fixed;
            bottom: 0;
            width: 7.5rem;
            // z-index: 2;
            .bottom_buttom_box{
                width: 7.5rem;
                height: 1.11rem;
                .span_{
                    position: relative;
                    height: 1.11rem;
                    line-height: 1.11rem;
                    font-size: .36rem;
                    text-align: center;
                }
                .left_box{
                    float: left;
                    width: 2.19rem;
                    color: #C91C18;
                    box-sizing: border-box;
                    border: .01rem solid #C91C18;
                    background: #fff;
                }
                .right_box{
                    float: right;
                    width: 5.31rem;
                    color: #fff;
                    background: #C91C18;
                    .icon_img_box_{
                        display: inline-block;
                        width: .34rem;
                    }
                }
            }
        }

        .fixation_box_{
            position: fixed;
            top: 3rem;
            right: 0;
            width: .87rem;
            .img_box_{
                width: .87rem;
                margin-top: -0.15rem;
            }
        }
        .complaint_box_{
            position: fixed;
            top: 8.1rem;
            right: 0;
            width: .48rem;
        }

         .manual_service{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 5.14rem;
            z-index: 11;
        }
        //遮罩
        .manual_service_shade{
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 10;
            background:  rgba(0,0,0,.5);
        }
    }
 </style>